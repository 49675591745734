import React from 'react';
import tw, { styled } from 'twin.macro';

const Mask = styled.div`
  left: -40px;
  right: -40px;
  ${tw`absolute h-140 bg-beige`}
  
  @media (min-width: 1024px) {
    height: 80%;
    left: -100px;
    right: -100px;
  }
  
  @media (min-width: 1280px) {
    height: 75%;
  }
`;

const MaskTop = styled(Mask)`
  top: -53px;
  transform: rotate(8deg);
  
  @media (min-width: 768px) {
    top: -70px;
  }
  
  @media (min-width: 1024px) {
    top: -90px;
    transform: rotate(10deg);
  }
  
  @media (min-width: 1280px) {
    top: -20px;
  }

  @media (min-width: 1921px) {
    transform: rotate(7deg);
  }
`;

const MaskBottom = styled(Mask)`
  bottom: -30px;
  transform: rotate(6deg);
  ${tw`md:hidden lg:block`}
  
  @media (min-width: 640px) {
    bottom: -50px;
    transform: rotate(4deg);
  }
  
  @media (min-width: 1024px) {
    bottom: 0;
    transform: none;
  }
`;

const Masks = () => (
  <>
    <MaskTop />
    <MaskBottom />
  </>
);

export default Masks;
