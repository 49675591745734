import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import BoxFirst from '@components/coffee/boxes/box/first';
import BoxSecond from '@components/coffee/boxes/box/second';
import BoxThird from '@components/coffee/boxes/box/third';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mt-40 px-10 text-center
    sm:w-460
    md:w-auto md:mt-100
    lg:flex lg:justify-between lg:mt-60 lg:px-0
    xl:mt-120
  `}
`;

const Boxes = () => (
  <Wrapper>
    <BoxFirst />
    <BoxSecond />
    <BoxThird />
  </Wrapper>
);

export default Boxes;
