import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Masks from '@components/coffee/etno-cafe/masks';
import Content from '@components/coffee/etno-cafe/content';
import Boxes from '@components/coffee/etno-cafe/boxes';
import Coffee from '@components/coffee/etno-cafe/coffee';

const StickyNavWrapper = tw.div``;

const Background = tw.div`relative mb-70 sm:mb-120 md:mb-0 bg-beige lg:bg-transparent`;

const Container = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 px-6 pb-36
    sm:w-460
    md:flex md:w-auto md:mb-65 md:px-10 md:pb-50 md:text-left
    lg:px-0 lg:pb-80
    xl:-mt-80 xl:pt-80
  `}
`;

const EtnoCafe = ({ lang }) => (
  <StickyNavWrapper id="etnoCafe">
    <Background>
      <Masks />
      <Container>
        <Content />
        <Boxes lang={lang} />
      </Container>
    </Background>
    <Coffee />
  </StickyNavWrapper>
);

EtnoCafe.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default EtnoCafe;
