import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import { graphql, useStaticQuery } from 'gatsby';

const Wrapper = tw.div`flex mb-12`;

const Image = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mt-10 mr-18 lg:mt-8`}
`;

const Item = ({ name, number }) => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Image fluid={data.listStyle.childImageSharp.fluid} />
      <Text fontSizes={16} lineHeight={[2.38, 2]} letterSpacing={20}>
        <FormattedMessage id={`coffee.how to brew.${name}.list.${number}`} />
      </Text>
    </Wrapper>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default Item;
