import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { customContainer } from '@utils/twin-macro';

import Content from '@components/coffee/cold-brew/content';
import List from '@components/coffee/cold-brew/list';
import Taste from '@components/coffee/cold-brew/taste';
import Brew from '@components/coffee/cold-brew/brew';
import Triangle from '@components/coffee/cold-brew/traingle';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-80 px-8 text-center
    sm:w-460
    md:flex md:flex-wrap md:w-auto md:mb-130 md:text-left
    lg:px-0
    xl:-mt-80 xl:pt-80
  `}
`;

const Beans = styled(Img)`
  top: 300px;
  left: -80px;
  transform: scaleX(-1);
  ${tw`hidden absolute! xl:block xl:w-58`}
`;

const ColdBrew = ({ handleQuestions }) => {
  const data = useStaticQuery(graphql`
    query {
      beans: file(relativePath: { eq: "coffee-beans-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 65) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper id="coldBrew">
      <Triangle />
      <Beans fluid={data.beans.childImageSharp.fluid} />
      <Content handleQuestions={handleQuestions} />
      <List />
      <Taste />
      <Brew />
    </Wrapper>
  );
};

ColdBrew.propTypes = {
  handleQuestions: PropTypes.func.isRequired,
};

export default ColdBrew;
