import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = styled.div`
  ${tw`
    hidden w-210 mb-15 text-left cursor-pointer transition-all duration-400
    md:flex
    lg:w-360
    xl:w-405
  `}

  ${({ active }) => active && tw`py-60 first:pt-0 first:pb-100 last:pt-100 last:pb-0`}
`;

const QuestionNumber = tw(Text)`pl-12 mr-8`;

const Question = styled(Text)`
  ${({ active }) => active && tw`font-600`}
`;

const Questions = ({ activeQuestion, number, handleQuestions }) => (
  <Wrapper onClick={() => handleQuestions(number)} active={activeQuestion === number}>
    <QuestionNumber fontSizes={9} fontWeight={600} letterSpacing={11}>
      {number}
    </QuestionNumber>
    <Question
      fontSizes={16}
      fontWeight={300}
      lineHeight={1.5}
      letterSpacing={20}
      active={activeQuestion === number}
    >
      <FormattedMessage id={`coffee.faq.questions.${number}.question`} />
    </Question>
  </Wrapper>
);

Questions.propTypes = {
  activeQuestion: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  handleQuestions: PropTypes.func.isRequired,
};

export default Questions;
