import React from 'react';
import tw, { styled, css } from 'twin.macro';

import Box from '@components/coffee/boxes/box';
import BoxShapeDefault from '@images/coffee/box-left.svg';

const Mask = styled.div`
  top: -35px;
  left: -200px;
  right: -200px;
  height: 140px;
  transform: rotate(-8deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
 
  @media (min-width: 640px) {
    height: 110px;
  }
`;

const MaskSecond = styled.div`
  bottom: 32px;
  left: -157px;
  width: 250px;
  height: 160px;
  border-radius: 2px;
  transform: rotate(22deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
  
  @media (min-width: 640px) {
    bottom: 23px;
    left: -166px;
    width: 320px;
    height: 110px;
    transform: rotate(14deg);
  }
`;

const MaskThird = styled.div`
  bottom: -16px;
  right: -173px;
  width: 418px;
  height: 220px;
  border-radius: 2px;
  transform: rotate(2deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
  
  @media (min-width: 640px) {
    bottom: -22px;
    width: 478px;
  }
`;

export const defaultBoxShadow = css`
  top: -50px;
  left: -18px;
  width: calc(100% + 32px);
  height: calc(100% + 80px);
  ${tw`hidden absolute -z-1 md:block`}

  @media (min-width: 1024px) {
    left: -5px;
    width: calc(100% + 10px);
}

  @media (min-width: 1280px) {
    left: -11px;
    width: calc(100% + 22px);
  }
`;

const BoxShadow = styled(BoxShapeDefault)`
  ${defaultBoxShadow}
  
  @media (min-width: 1024px) {
    top: -55px;
    height: calc(100% + 85px);
  }
`;

const First = () => (
  <Box number={0}>
    <Mask />
    <MaskSecond />
    <MaskThird />
    <BoxShadow />
  </Box>
);

export default First;
