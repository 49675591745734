import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';

const Wrapper = tw.div`mb-30 md:w-300 lg:w-422 lg:mb-0`;

const SeeThemAll = styled(Text)`
  ${tw`mt-15`}

  b { ${tw`font-600`} }
`;

const Taste = () => (
  <Wrapper>
    <Text fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="coffee.cold brew.taste" />
    </Text>
    <SeeThemAll fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee.cold brew.see them all" />
    </SeeThemAll>
    <Link to="https://sklep.etnocafe.pl/290-cold-brew-coffee" isExternal>
      <Text fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20} underline>
        <FormattedHTMLMessage id="coffee.cold brew.shop link" />
      </Text>
    </Link>
  </Wrapper>
);

export default Taste;
