import React from 'react';
import tw, { styled } from 'twin.macro';

const Element = styled.div`
  bottom: 68px;
  left: -190px;
  border-bottom: 695px solid #D7EFFD;
  border-right: 1435px solid transparent;
  transform: rotate(3deg);
  ${tw`absolute w-0 h-0 -z-1`}
  
  @media (min-width: 768px) {
    bottom: -40px;
    left: -140px;
    transform: rotate(2deg);
    border-bottom-width: 470px;
    border-right-width: 990px;
  }
  
  @media (min-width: 1024px) {
    bottom: -40px;
    left: -430px;
    border-bottom-width: 610px;
    border-right-width: 1485px;
  }
  
  @media (min-width: 1921px) {
    bottom: -35px;
    left: -740px;
    border-bottom-width: 740px;
    border-right-width: 1795px;
  }
`;

const Triangle = () => <Element />;

export default Triangle;
