import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import CTA from '@components/coffee/cold-brew/cta';

const Wrapper = tw.div`mb-55 md:w-300 lg:w-422 lg:mb-130`;

const Description = styled(Text)`
  ${tw`mt-22 md:mt-13`}

  b { ${tw`font-600`} }
`;

const Content = ({ handleQuestions }) => (
  <Wrapper>
    <Text fontFamily="bebasNeue" fontSizes={[42, 54]} fontWeight={700} lineHeight={1.4} letterSpacing={[107, 138]}>
      <FormattedMessage id="coffee.cold brew.title" />
    </Text>
    <Description fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee.cold brew.description" />
    </Description>
    <CTA handleQuestions={handleQuestions} />
  </Wrapper>
);

Content.propTypes = {
  handleQuestions: PropTypes.func.isRequired,
};

export default Content;
