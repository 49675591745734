import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ShapeDefault from '@images/coffee/shape.svg';
import FaceDefault from '@images/coffee/face.svg';

const Wrapper = styled.div`
  ${tw`relative mb-45 md:mb-170 lg:mb-152`}
  
  @media (min-width: 768px) { width: 432px; }
`;

const Description = styled(Text)`
  margin-top: 406px;
  ${tw`md:mt-30`}

  b { ${tw`font-600`} }
`;

const Image = styled(Img)`
  top: 72px;
  left: 26px;
  width: 232px;
  ${tw`absolute! -z-1 md:w-370 lg:w-440`}
  
  @media (min-width: 640px) {
    left: 70px;
  }
  
  @media (min-width: 768px) {
    left: initial;
    right: -400px;
    top: -80px;
  }
  
  @media (min-width: 1024px) {
    z-index: initial;
    top: -185px;
    right: -500px;
  }
  
  @media (min-width: 1280px) {
    right: -680px;
  }
`;

const Shape = styled(ShapeDefault)`
  top: -28px;
  left: -40px;
  transform: scaleY(-1);
  ${tw`absolute! w-290 -z-1 sm:left-0`}
  
  @media (min-width: 768px) {
    top: 10px;
    left: -140px;
  }
  
  @media (min-width: 1024px) {
    top: 5px;
    left: -170px;
  }
`;

const Face = styled(FaceDefault)`
  top: -75px;
  right: -770px;
  ${tw`hidden absolute! lg:block -z-1`}
  
  @media (min-width: 1280px) { right: -935px; }
`;

const Taste = () => {
  const data = useStaticQuery(graphql`
    query {
      wroasters: file(relativePath: { eq: "coffee/taste.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Shape />
      <Face />
      <Image fluid={data.wroasters.childImageSharp.fluid} />
      <Text fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
        <FormattedMessage id="coffee.wroasters.new taste.title" />
      </Text>
      <Description fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="coffee.wroasters.new taste.desc" />
      </Description>
    </Wrapper>
  );
};

export default Taste;
