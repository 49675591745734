import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import { graphql, useStaticQuery } from 'gatsby';

const Wrapper = tw.div`
  text-left mb-42
  md:w-370 md:mt-32 md:ml-auto
  lg:w-520 lg:ml-52 lg:mb-0
  xl:w-auto xl:max-w-xl
`;

const Title = tw(Text)`mb-14 md:mb-20`;

const ItemWrapper = tw.div`flex mb-12`;

const Image = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mt-4 mr-18`}
`;

const List = () => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Title fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
        <FormattedMessage id="coffee.cold brew.list.title" />
      </Title>
      {[1, 2, 3, 4, 5, 6].map((number) => (
        <ItemWrapper key={number}>
          <Image fluid={data.listStyle.childImageSharp.fluid} />
          <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
            <FormattedHTMLMessage id={`coffee.cold brew.list.${number}`} />
          </Text>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

export default List;
