import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`flex items-start mb-25 px-6 lg:mb-25`;

const Item = styled(Text)`
  b { ${tw`font-600`} }
`;

const Image = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mt-2 mr-16`}
`;

const ContentList = ({ item }) => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Image fluid={data.listStyle.childImageSharp.fluid} />
      <Item fontSizes={16} fontWeight={300} lineHeight={1.4} letterSpacing={20}>
        <FormattedHTMLMessage id={`coffee.etno cafe.header.list.${item}`} />
      </Item>
    </Wrapper>
  );
};

ContentList.propTypes = {
  item: PropTypes.number.isRequired,
};

export default ContentList;
