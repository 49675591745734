import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Masks from '@components/coffee/wroasters/masks';
import Taste from '@components/coffee/wroasters/taste';
import Brewing from '@components/coffee/wroasters/brewing';
import CTA from '@components/coffee/wroasters/cta';

const Background = tw.div`relative`;

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-130 pt-100 px-10 text-center
    sm:w-460
    md:w-auto md:text-left
    lg:mb-100 lg:pt-140 lg:px-0
  `}
`;

const Title = tw(Text)`mb-15 md:mb-0`;

const Wroasters = ({ handleQuestions }) => (
  <Background>
    <Wrapper id="wroasters">
      <Masks />
      <Title fontFamily="bebasNeue" fontSizes={54} lineHeight={[1.4, 1.38]} letterSpacing={138}>
        <FormattedHTMLMessage id="coffee.wroasters.header" />
      </Title>
      <Taste />
      <Brewing />
      <CTA handleQuestions={handleQuestions} />
    </Wrapper>
  </Background>
);

Wroasters.propTypes = {
  handleQuestions: PropTypes.func.isRequired,
};

export default Wroasters;
