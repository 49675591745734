import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Types from '@components/coffee/how-to-brew/types';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-56 px-10 text-center
    sm:w-460
    md:w-auto md:text-left
    lg:flex lg:justify-between lg:mb-65 lg:px-0
    xl:-mt-60 xl:pt-60
  `}
`;

const Content = tw.div`lg:w-400 xl:w-440`;

const Subtitle = styled(Text)`
  ${tw`mt-15 mb-35`}
  
  b { ${tw`font-600`} }
`;

const HowToBrew = () => (
  <Wrapper id="howToBrew">
    <Content>
      <Text fontFamily="bebasNeue" fontSizes={[42, 54]} lineHeight={1.4} letterSpacing={[107, 138]}>
        <FormattedMessage id="coffee.how to brew.title" />
      </Text>
      <Subtitle fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="coffee.how to brew.subtitle" />
      </Subtitle>
    </Content>
    <Types />
  </Wrapper>
);

export default HowToBrew;
