import React from 'react';
import PropTypes from 'prop-types';
import { array } from '@utils/helpers';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedHTMLMessage, FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Images from '@components/coffee/faq/images';
import Mobile from '@components/coffee/faq/mobile';
import Questions from '@components/coffee/faq/questions';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 pt-42 px-10 pb-20 text-center
    sm:w-460
    md:flex md:justify-between md:w-auto md:pt-50 md:text-left
    lg:px-0 lg:pb-0
  `}

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity .3s;
  }
`;

const Title = tw(Text)`mb-12 md:mb-0`;

const QuestionsWrapper = tw.div`md:mt-12`;

const AnswerText = styled(Text)`
  ${tw`hidden mt-12 w-380 h-890 md:block lg:w-595 lg:h-680 xl:h-630`}
  
  b { ${tw`font-600`} }
`;

const FAQ = ({ activeQuestion, handleQuestions }) => (
  <Wrapper id="FAQ">
    <Images />
    <Title fontFamily="bebasNeue" fontSizes={[42, 54]} lineHeight={1.4} letterSpacing={[107, 138]}>
      <FormattedMessage id="coffee.faq.title" />
    </Title>
    <QuestionsWrapper>
      <div>
        {array(8).map((number) => (
          <Mobile key={number} number={number} activeQuestion={activeQuestion} />
        ))}
      </div>
      <div>
        {array(8).map((number) => (
          <Questions
            key={number}
            activeQuestion={activeQuestion}
            number={number}
            handleQuestions={handleQuestions}
          />
        ))}
      </div>
    </QuestionsWrapper>
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={activeQuestion}
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        classNames="fade"
      >
        <AnswerText
          fontSizes={16}
          fontWeight={300}
          lineHeight={1.5}
          letterSpacing={20}
        >
          <FormattedHTMLMessage id={`coffee.faq.questions.${activeQuestion}.answer`} />
        </AnswerText>
      </CSSTransition>
    </SwitchTransition>
  </Wrapper>
);

FAQ.propTypes = {
  activeQuestion: PropTypes.number.isRequired,
  handleQuestions: PropTypes.func.isRequired,
};

export default FAQ;
