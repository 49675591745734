import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Wrapper = tw.div`flex`;

const Image = styled(Img)`
  min-width: 14px;
  min-height: 14px;
  ${tw`w-14 h-14 mr-8 last:mr-0`}
`;

const Score = ({ score }) => {
  const data = useStaticQuery(graphql`
    query {
      cafe: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      {[...Array(score).keys()].map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Image key={index} fluid={data.cafe.childImageSharp.fluid} />
      ))}
      {[...Array(5 - score).keys()].map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Image key={`no${index}`} fluid={data.mark.childImageSharp.fluid} />
      ))}
    </Wrapper>
  );
};

Score.propTypes = {
  score: PropTypes.number.isRequired,
};

export default Score;
