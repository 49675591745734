import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Header from '@components/coffee/header';
import EtnoCafe from '@components/coffee/etno-cafe';
import Wroasters from '@components/coffee/wroasters';
import ColdBrew from '@components/coffee/cold-brew';
import HowToBrew from '@components/coffee/how-to-brew';
import FAQ from '@components/coffee/faq';
import Boxes from '@components/coffee/boxes';

const navItems = ['header', 'etnoCafe', 'wroasters', 'coldBrew', 'howToBrew', 'FAQ'];

const CoffeePage = () => {
  const [activeQuestion, handleQuestions] = useState(1);
  const intl = useIntl();

  return (
    <Layout stickyNav={navItems}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.coffee' })}
        description={intl.formatMessage({ id: 'meta tags.description.coffee' })}
      />
      <Header />
      <EtnoCafe lang={intl.locale} />
      <Wroasters handleQuestions={handleQuestions} />
      <ColdBrew handleQuestions={handleQuestions} />
      <HowToBrew />
      <FAQ activeQuestion={activeQuestion} handleQuestions={handleQuestions} />
      <Boxes />
    </Layout>
  );
};

export default CoffeePage;
