import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Triangles from '@components/coffee/header/triangles';
import Images from '@components/coffee/header/images';
import CTA from '@components/coffee/header/cta';
import Content from '@components/coffee/header/content';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 pt-42 px-10 pb-110 text-center
    sm:w-auto sm:pt-80
    md:mb-50 md:pt-120 md:text-left
    lg:mb-150 lg:pt-172 lg:px-0 lg:pb-270
  `}
`;

const Index = tw.div`relative`;

const Header = () => (
  <Wrapper id="header">
    <Triangles />
    <Images />
    <Index>
      <Content />
      <CTA />
    </Index>
  </Wrapper>
);

export default Header;
