import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';
import ShapeDefault from '@images/coffee/shape.svg';

const Wrapper = tw.div`relative mb-38 md:w-420 md:ml-auto lg:w-535`;

const Title = styled(Text)`
  margin-bottom: 366px;
  ${tw`md:mb-30`}
`;

const Guide = tw(Text)`mt-15 lg:mt-24`;

const CTA = styled(Text)`
  ${tw`lg:pr-40`}
  
  a { ${tw`underline`} }
`;

const Image = styled(Img)`
  left: -60px;
  ${tw`absolute! top-0 w-400 -z-1 md:w-420 lg:w-530`}

  @media (min-width: 640px) {
    left: -20px;
  }
  
  @media (min-width: 768px) {
    top: -130px;
    left: -420px;
  }
  
  @media (min-width: 1024px) {
    top: -200px;
    left: -540px;
  }
  
  @media (min-width: 1280px) {
    left: -645px;
  }
`;

const Shape = styled(ShapeDefault)`
  top: 205px;
  left: -40px;
  ${tw`absolute! -z-1 sm:left-0`}
  
  @media (min-width: 768px) {
    top: 120px;
    left: -520px;
  }
  
  @media (min-width: 1024px) {
    top: 135px;
    left: -600px;
  }
  
  @media (min-width: 1280px) {
    left: -730px;
  }
`;

const ShapeExtra = styled(ShapeDefault)`
  bottom: -550px;
  right: -180px;
  transform: scale(-1);
  ${tw`hidden absolute -z-1 lg:block`}
  
  @media (min-width: 1280px) {
    bottom: -560px;
    right: -180px;
  }
`;

const Brewing = () => {
  const data = useStaticQuery(graphql`
    query {
      brewing: file(relativePath: { eq: "coffee/brewing.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Shape />
      <ShapeExtra />
      <Image fluid={data.brewing.childImageSharp.fluid} />
      <Title fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
        <FormattedMessage id="coffee.wroasters.new brewing.title" />
      </Title>
      <Text fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
        <FormattedMessage id="coffee.wroasters.new brewing.desc" />
      </Text>
      <Guide fontSizes={16} fontWeight={600} lineHeight={1.63} letterSpacing={20}>
        <FormattedMessage id="coffee.wroasters.guide" />
      </Guide>
      <CTA fontSizes={16} fontWeight={300} lineHeight={1.63} letterSpacing={20}>
        <Link to="https://sklep.etnocafe.pl/63-szkolenia" isExternal>
          <FormattedMessage id="coffee.wroasters.training" />
        </Link>
        <FormattedMessage id="coffee.wroasters.or" />
        <Link to="https://youtube.com/playlist?list=PLPiGVtUXsNpJRHaOZ4aeBPFuFNfJzBti6" isExternal>
          <FormattedMessage id="coffee.wroasters.course" />
        </Link>
      </CTA>
    </Wrapper>
  );
};

export default Brewing;
