import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { css, styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';
import CafeShadow from '@images/coffee-houses/cafe-shadow.svg';
import EggsShadow from '@images/coffee-houses/eggs-shadow.svg';

const Wrapper = styled(Link)`
  ${tw`
    relative flex items-center mb-14 py-20 pl-16 pr-22
    sm:pr-30 md:mb-24 md:pr-24
    lg:mb-34 lg:pr-30
    xl:pr-36
    last:mb-0
  `}

  &:hover svg { ${tw`opacity-80`} }
  
  :nth-of-type(2n+ 1) { ${tw`lg:mr-50`} }
  
  :nth-of-type(2n+ 2) { ${tw`lg:ml-30`} }
`;

const Score = styled(Text)`
  min-width: 65px;
  ${tw`relative flex justify-center items-center w-65 h-65 mr-18`}
  
  b { ${tw`font-600`} }
  
  span {
    :nth-of-type(2) { ${tw`-mx-2`} }
    
    :nth-of-type(1),
    :nth-of-type(3) {
      ${tw`relative`}
    }

    :nth-of-type(1) { top: -10px; }
    
    :nth-of-type(3) { top: 10px; }
  }
`;

const Image = styled(Img)`
  left: -2px;
  ${tw`absolute! w-64`}
`;

const Item = tw(Text)`relative lg:pr-30`;

const shadowStyles = css`
  top: -12px;
  left: -9px;
  width: calc(100% + 12px);
  height: calc(100% + 27px);
  ${tw`absolute opacity-100 transition-opacity duration-300`}
`;

const ShadowCafe = styled(CafeShadow)`${shadowStyles}`;

const ShadowEggs = styled(EggsShadow)`${shadowStyles}`;

const Box = ({ link, boxNumber }) => {
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper to={link} isExternal>
      { (boxNumber === 1 || boxNumber === 4) ? <ShadowEggs /> : <ShadowCafe />}
      <Score fontSizes={13} fontWeight={300} lineHeight={1.4} letterSpacing={16}>
        <Image fluid={data.mark.childImageSharp.fluid} />
        <span>
          <b><FormattedMessage id="coffee.etno cafe.boxes.score range" /></b>
        </span>
        <span> / </span>
        <span>
          <FormattedMessage id={`coffee.etno cafe.boxes.box.${boxNumber}.score`} />
        </span>
      </Score>
      <Item fontSizes={13} fontWeight={300} lineHeight={1.54} letterSpacing={16}>
        <FormattedHTMLMessage id={`coffee.etno cafe.boxes.box.${boxNumber}.text`} />
      </Item>
    </Wrapper>
  );
};

Box.propTypes = {
  link: PropTypes.string.isRequired,
  boxNumber: PropTypes.number.isRequired,
};

export default Box;
