import React from 'react';
import tw, { styled } from 'twin.macro';
import { array } from '@utils/helpers';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Item from '@components/coffee/how-to-brew/item';

const types = [
  { name: 'etno', list: array(5) },
  { name: 'wroasters', list: array(3) },
];

const Wrapper = tw.div`md:flex md:justify-between`;

const Type = tw.div`mb-50 md:w-340 lg:w-270 lg:first:mr-20 xl:first:mr-50`;

const Recomended = tw(Text)`py-15`;

const List = styled.div`
  ${({ name }) => name === 'wroasters' && tw`inline-block`}
`;

const Types = () => (
  <Wrapper>
    {types.map(({ name, list }) => (
      <Type key={name}>
        <Text fontFamily="bebasNeue" fontSizes={28} fontWeight={700} lineHeight={2.04} letterSpacing={35}>
          <FormattedMessage id={`coffee.how to brew.${name}.title`} />
        </Text>
        <Text fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
          <FormattedMessage id={`coffee.how to brew.${name}.description`} />
        </Text>
        <Recomended fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
          <FormattedMessage id="coffee.how to brew.recommended" />
        </Recomended>
        <List name={name}>
          {list.map((number) => (
            <Item key={`${number}${name}`} number={number} name={name} />
          ))}
        </List>
      </Type>
    ))}
  </Wrapper>
);

export default Types;
