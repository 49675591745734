import React from 'react';
import tw, { styled } from 'twin.macro';
import { defaultBoxShadow } from '@components/coffee/boxes/box/first';

import Box from '@components/coffee/boxes/box';
import BoxShapeDefault from '@images/coffee/box-right.svg';

const Mask = styled.div`
  top: -25px;
  left: -180px;
  right: -180px;
  height: 200px;
  ${tw`absolute bg-red -z-1 md:hidden`}
`;

const MaskSecond = styled.div`
  bottom: -18px;
  left: -180px;
  right: -180px;
  height: 200px;
  transform: rotate(5deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
`;

const BoxShadow = styled(BoxShapeDefault)`
  ${defaultBoxShadow}
  top: -30px;

  @media (min-width: 1024px) {
    top: -25px;
    height: calc(100% + 72px);
  }
`;

const Third = () => (
  <Box number={2}>
    <Mask />
    <MaskSecond />
    <BoxShadow />
  </Box>
);

export default Third;
