import React from 'react';
import tw, { styled } from 'twin.macro';
import { defaultBoxShadow } from '@components/coffee/boxes/box/first';

import Box from '@components/coffee/boxes/box';
import BoxShapeDefault from '@images/coffee/box-center.svg';

const Mask = styled.div`
  top: -25px;
  left: -180px;
  right: -180px;
  height: 150px;
  transform: rotate(3deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
`;

const MaskSecond = styled.div`
  bottom: 29px;
  left: -201px;
  width: 320px;
  height: 160px;
  border-radius: 2px;
  transform: rotate(22deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
  
  @media (min-width: 640px) {
    bottom: 30px;
    left: -181px;
    width: 370px;
    height: 140px;
    transform: rotate(20deg);
  }
`;

const MaskThird = styled.div`
  bottom: -11px;
  right: -180px;
  width: 410px;
  height: 150px;
  border-radius: 2px;
  transform: rotate(-4deg);
  ${tw`absolute bg-red -z-1 md:hidden`}
  
  @media (min-width: 640px) {
    bottom: -12px;
    right: -181px;
    width: 475px;
  }
`;

const BoxShadow = styled(BoxShapeDefault)`
  ${defaultBoxShadow}

  @media (min-width: 1024px) {
    top: -40px;
    height: calc(100% + 65px);
  }
`;

const Second = () => (
  <Box number={1}>
    <Mask />
    <MaskSecond />
    <MaskThird />
    <BoxShadow />
  </Box>
);

export default Second;
