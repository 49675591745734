import React, { useState } from 'react';
import Img from 'gatsby-image';
import { array } from '@utils/helpers';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import NavMobile from '@components/coffee/cold-brew/nav-mobile';
import NavDesktop from '@components/coffee/cold-brew/nav-desktop';

const Wrapper = tw.div`
  relative flex flex-col items-center pb-330
  md:items-start md:ml-82 md:pb-0
  lg:ml-52
`;

const Background = styled(Img)`
  top: 25px;
  ${tw`absolute! w-275 -z-1 md:w-270 lg:w-290`}
  
  @media (min-width: 640px) {
    left: 70px;
  }
  
  @media (min-width: 768px) {
    top: -70px;
    left: 145px;
  }
  
  @media (min-width: 1024px) {
    top: -110px;
    left: 250px;
  }
`;

const ColdBrew = styled(Img)`
  top: 30px;
  left: -110px;
  ${tw`absolute! w-540 -z-1 lg:w-590`}
  
  @media (min-width: 640px) {
    left: -50px;
  }
  
  @media (min-width: 768px) {
    top: -70px;
    left: 20px;
  }
  
  @media (min-width: 1024px) {
    top: -110px;
    left: 110px;
  }
`;

const Brew = () => {
  const data = useStaticQuery(graphql`
    query {
      brewBackground: file(relativePath: { eq: "coffee/brew-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      taste1: file(relativePath: { eq: "coffee/cold-brew/classic.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      taste2: file(relativePath: { eq: "coffee/cold-brew/apple.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      taste3: file(relativePath: { eq: "coffee/cold-brew/spicy.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      taste4: file(relativePath: { eq: "coffee/cold-brew/orange.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      taste5: file(relativePath: { eq: "coffee/cold-brew/vanilla.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [taste, handleTaste] = useState(1);
  const flavors = array(5);

  return (
    <Wrapper>
      <NavMobile array={flavors} taste={taste} handleTaste={handleTaste} />
      <NavDesktop array={flavors} taste={taste} handleTaste={handleTaste} />
      <Background fluid={data.brewBackground.childImageSharp.fluid} />
      <ColdBrew fluid={data[`taste${taste}`].childImageSharp.fluid} />
    </Wrapper>
  );
};

export default Brew;
