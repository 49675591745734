import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`hidden md:block`;

const ItemWrapper = styled.div`
  ${tw`flex mb-16 cursor-pointer`}
`;

const ListStyle = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mt-2 mr-18`}
`;

const NavDesktop = ({ array, taste, handleTaste }) => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      {array.map((number) => (
        <ItemWrapper key={number} onClick={() => handleTaste(number)}>
          <ListStyle fluid={data.listStyle.childImageSharp.fluid} />
          <Text
            fontSizes={16}
            fontWeight={number === taste ? 600 : 300}
            lineHeight={1.4}
            letterSpacing={20}
          >
            <FormattedMessage id={`coffee.cold brew.types.${number}`} />
          </Text>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

NavDesktop.propTypes = {
  array: PropTypes.arrayOf(PropTypes.number).isRequired,
  taste: PropTypes.number.isRequired,
  handleTaste: PropTypes.func.isRequired,
};

export default NavDesktop;
