import React from 'react';
import tw from 'twin.macro';
import { array } from '@utils/helpers';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ContentList from '@components/coffee/etno-cafe/content-list';

const Wrapper = tw.div`mb-36 md:w-1/2 md:mb-0 md:pr-48 lg:w-418 lg:mr-auto lg:pr-0`;

const TextMobileCenter = tw(Text)`text-center md:text-left`;

const Title = tw(TextMobileCenter)`mb-10 lg:mb-15`;

const Subtitle = tw(TextMobileCenter)`mb-30 lg:mb-15`;

const Depends = tw(TextMobileCenter)`mt-30 lg:-mt-10`;

const Content = () => (
  <Wrapper>
    <Title fontFamily="bebasNeue" fontSizes={[42, 58]} fontWeight={300} lineHeight={[1.36, 0.98]} letterSpacing={[52, 72]}>
      <FormattedHTMLMessage id="coffee.etno cafe.header.title" />
    </Title>
    <Subtitle fontSizes={16} fontWeight={300} lineHeight={1.4} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee.etno cafe.header.subtitle" />
    </Subtitle>
    {array(3).map((item) => (
      <ContentList key={item} item={item} />
    ))}
    <Depends fontSizes={16} fontWeight={300} lineHeight={1.4} letterSpacing={20}>
      <FormattedMessage id="coffee.etno cafe.header.depends on you" />
    </Depends>
  </Wrapper>
);

export default Content;
