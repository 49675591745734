import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = styled.div`
  ${tw`flex justify-between mt-22`}
  ${({ special }) => special && tw`items-center mt-0`}
`;

const Category = ({ children, category, special }) => (
  <Wrapper special={special}>
    <Text fontSizes={14} fontWeight={300} lineHeight={special ? 2 : 1.71} letterSpacing={18}>
      <FormattedMessage id={`coffee.etno cafe.category.${category}`} />
    </Text>
    { children }
  </Wrapper>
);

Category.defaultProps = {
  special: false,
};

Category.propTypes = {
  children: PropTypes.node.isRequired,
  category: PropTypes.string.isRequired,
  special: PropTypes.bool,
};

export default Category;
