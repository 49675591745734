import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { navigationColdBrew } from '@utils/navigation';

import Text from '@components/text';
import ButtonDefault from '@components/button';

const Wrapper = tw.div`relative inline-block mt-30 cursor-pointer`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-30`;

const CTA = ({ handleQuestions }) => (
  <Wrapper onClick={() => {
    handleQuestions(6);
    scrollTo('#FAQ');
  }}
  >
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id={navigationColdBrew.translation} />
      </Text>
    </Button>
  </Wrapper>
);

CTA.propTypes = {
  handleQuestions: PropTypes.func.isRequired,
};

export default CTA;
