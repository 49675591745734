import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';
import { navigationFindInShop } from '@utils/navigation';

const Link = tw(LinkDefault)`relative my-30 inline-block lg:mt-55 lg:mb-40`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-30`;

const CTA = () => (
  <Link to={navigationFindInShop.path} isExternal={navigationFindInShop.externalLink}>
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id={navigationFindInShop.translation} />
      </Text>
    </Button>
  </Link>
);

export default CTA;
