import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { currentBreakpoint, debounce } from '@utils/helpers';

import Text from '@components/text';
import Coffee from '@components/coffee/etno-cafe/coffee/coffee';

const handleHeightComponent = (width) => {
  const steps = {
    xs: 995, md: 1020, lg: 1010, xl: 1010,
  };

  return steps[currentBreakpoint(width)];
};

const Wrapper = tw.div`relative w-full pl-42 pr-26 md:flex md:-mx-12 lg:-mx-16`;

const StyledAnimateHeight = styled(AnimateHeight)`
  ${tw`w-full`}
  
  & > div { ${tw`flex items-start flex-wrap`} }
`;

const Shadow = styled.div`
  bottom: -50px;
  height: 174px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) -43%, #ffffff 55%);
  ${tw`absolute left-0 w-full transition-all duration-400`}
  
  ${Wrapper}.hide & { ${tw`h-0 opacity-0`} }
  
  @media (min-width: 768px) { bottom: -58px; }
  
  @media (min-width: 1024px) { bottom: -67px; }
`;

const ShowMore = styled(Text)`
  transition: opacity .5s, left 0s .5s;
  ${tw`absolute left-1/2 bottom-0 text-center cursor-pointer transform -translate-x-1/2`}

  ${Wrapper}.hide & { ${tw`-left-full opacity-0`} }
  
  @media (min-width: 1024px) { bottom: -20px; }
`;

const Grid = () => {
  const { allSanityCoffee } = useStaticQuery(graphql`
    query {
      allSanityCoffee(sort: {fields: _createdAt}) {
        nodes {
          id
          image {
            asset {
              fluid(maxWidth: 180) {
                ...GatsbySanityImageFluid
              }
            }
          }
          name
          type {
            name
          }
          acidity
          sweetness
          bitterness
          taste {
            name_en
            name_pl
          }
          sca {
            name_pl
            name_en
          }
          coffee_grain {
            name_pl
            name_en
          }
          url_pl
        }
      }
    }
  `);

  const windowInnerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const [isFullHeight, handleHeight] = useState(false);
  const [viewWidth, setWidth] = useState(windowInnerWidth);
  const [height, setHeight] = useState(1020);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    // eslint-disable-next-line no-unused-vars
    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    setHeight(handleHeightComponent(viewWidth));
  }, [viewWidth]);

  return (
    <Wrapper className={isFullHeight || allSanityCoffee.nodes.length < 8 ? 'hide' : ''}>
      <StyledAnimateHeight
        duration={1200}
        height={isFullHeight || allSanityCoffee.nodes.length < 8 ? 'auto' : height}
      >
        {allSanityCoffee.nodes.map((coffee) => (
          <Coffee key={coffee.id} coffee={coffee} />
        ))}
      </StyledAnimateHeight>
      <Shadow />
      <ShowMore
        uppercase
        underline
        fontSizes={16}
        fontWeight={600}
        letterSpacing={20}
        onClick={() => handleHeight(true)}
      >
        <FormattedMessage id="btn.show more" />
      </ShowMore>
    </Wrapper>
  );
};

export default Grid;
