import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Main = styled(Img)`
  top: -10px;
  right: -60px;
  ${tw`hidden absolute! md:block md:w-400 lg:w-580`}

  @media (min-width: 1024px) {
  top: 50px;
  right: -10px;
}
`;

const Cafe = styled(Img)`
  top: 460px;
  left: -220px;
  transform: scaleX(-1);
  ${tw`hidden absolute! w-180 lg:block`}
`;

const Beans = styled(Img)`
  top: -10px;
  left: -5px;
  ${tw`absolute! w-90`}
  
  @media (min-width: 640px) {
    left: 20px;
  }
  
  @media (min-width: 768px) {
    top: 30px;
    left: -40px;
  }
  
  @media (min-width: 1024px) {
    top: 60px;
    left: -65px;
  }
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "coffee/main.png" }) {
        childImageSharp {
          fluid(maxWidth: 680) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cafe: file(relativePath: { eq: "cafe.png" }) {
        childImageSharp {
          fluid(maxWidth: 680) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beans: file(relativePath: { eq: "coffee-beans-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Main fluid={data.main.childImageSharp.fluid} />
      <Cafe fluid={data.cafe.childImageSharp.fluid} />
      <Beans fluid={data.beans.childImageSharp.fluid} />
    </>
  );
};

export default Images;
