import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';
import Score from '@components/coffee/etno-cafe/coffee/score';
import Category from '@components/coffee/etno-cafe/coffee/category';

const Wrapper = styled(Link)`
  ${tw`inline-block w-full mb-45 last:mb-0 md:w-1/3 md:px-12 lg:w-1/4 lg:px-16`}
`;

const Image = tw(Img)`w-122 mb-25`;

const Hashtag = tw(Text)`-mb-14`;

const Name = tw(Text)`my-14`;

const TextRight = styled.div`
  max-width: 120px;
  ${tw`text-right`}
`;

const Coffee = ({ coffee }) => {
  const { locale } = useIntl();

  return (
    <Wrapper to={coffee.url_pl} isExternal>
      <Image fluid={coffee.image.asset.fluid} />
      <Hashtag color="pink" fontSizes={14} fontWeight={300} lineHeight={2} letterSpacing={18}>
        {coffee.type.name}
      </Hashtag>
      <Name fontFamily="bebasNeue" fontSizes={25} fontWeight={700} lineHeight={1.2} letterSpacing={31}>
        {coffee.name}
      </Name>
      <Category category="acidity" special>
        <Score score={coffee.acidity} />
      </Category>
      <Category category="sweetness" special>
        <Score score={coffee.sweetness} />
      </Category>
      <Category category="bitterness" special>
        <Score score={coffee.bitterness} />
      </Category>
      <Category category="taste">
        <Text fontSizes={14} fontWeight={600} lineHeight={1.71} letterSpacing={18}>
          {coffee.taste.map((item, index) => (
            <TextRight key={item.name_en}>
              {item[`name_${locale}`]}
              {coffee.taste.length !== index + 1 && ','}
            </TextRight>
          ))}
        </Text>
      </Category>
      <Category category="sca">
        <Text fontSizes={14} fontWeight={600} lineHeight={1.71} letterSpacing={18}>
          {coffee.sca[`name_${locale}`]}
        </Text>
      </Category>
      <Category category="grain processing">
        <Text fontSizes={14} fontWeight={600} lineHeight={1.71} letterSpacing={18}>
          {coffee.coffee_grain[`name_${locale}`]}
        </Text>
      </Category>
    </Wrapper>
  );
};

Coffee.propTypes = {
  coffee: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(
        PropTypes.string,
      ),
      PropTypes.objectOf(
        PropTypes.objectOf(
          PropTypes.objectOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
          ),
        ),
      ),
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.string,
        ),
      ),
    ]),
  ).isRequired,
};

export default Coffee;
