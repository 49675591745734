import React from 'react';
import tw, { styled } from 'twin.macro';

const Mask = styled.div`
  left: -330px;
  right: -330px;
  ${tw`absolute h-1400 bg-red -z-1 md:h-800`}
  
  @media (min-width: 1024px) {
    left: -500px;
    right: -500px;
  }
  
  @media (min-width: 1921px) {
    left: -800px;
    right: -800px;
  }
`;

const MaskWhite = styled.div`
  top: 30px;
  left: -330px;
  right: -330px;
  transform: rotate(8deg);
  ${tw`hidden absolute h-80 bg-white lg:block`}
`;

const MaskTop = styled(Mask)`
  top: 50px;
  transform: rotate(8deg);

  @media (min-width: 1024px) { top: 115px; }
`;

const MaskBottom = styled(Mask)`
  bottom: -72px;
  transform: rotate(6deg);

  @media (min-width: 768px) {
    bottom: -30px;
  }
  
  @media (min-width: 1280px) {
    bottom: -30px;
    transform: rotate(4deg);
  }
`;

const MaskExtra = styled.div`
  right: -390px;
  bottom: -260px;
  transform: rotate(35deg);
  ${tw`hidden absolute w-600 h-350 -z-1 bg-red lg:block xl:h-340 xl:w-900`}
  
  @media (min-width: 1280px) {
    right: -660px;
    bottom: -340px;
  }
  
  @media (min-width: 1921px) {
    right: -965px;
    bottom: -435px;
    width: 1170px;
    height: 500px;
  }
`;

const Masks = () => (
  <>
    <MaskWhite />
    <MaskTop />
    <MaskBottom />
    <MaskExtra />
  </>
);

export default Masks;
