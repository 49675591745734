import React from 'react';
import tw, { styled } from 'twin.macro';

const Triangle = tw.div`absolute w-0 h-0`;

const TrianglePink = styled(Triangle)`
  top: 0;
  left: -160px;
  border-top: 580px solid #f2c1b6;
  border-right: 610px solid transparent;

  @media (min-width: 640px) {
    left: -70px;
    border-top-width: 500px;
    border-right-width: 770px;
  }

  @media (min-width: 768px) {
    top: 70px;
    left: -130px;
    border-top-width: 430px;
    border-right-width: 540px;
  }
  
  @media (min-width: 1024px) {
    top: 92px;
    left: -410px;
    border-top-width: 658px;
    border-right-width: 840px;
  }
  
  @media (min-width: 1921px) {
    left: -730px;
    border-top-width: 910px;
    border-right-width: 1160px;
  }
`;

const TriangleBlue = styled(Triangle)`
  top: 0;
  right: -130px;
  border-top: 412px solid #D7EFFD;
  border-left: 430px solid transparent;
  ${tw`hidden md:block`}
  
  @media (min-width: 1024px) {
    right: -410px;
    border-top-width: 1080px;
    border-left-width: 920px;
  }

  @media (min-width: 1921px) {
    right: -730px;
    border-top-width: 1400px;
    border-left-width: 1240px;
  }
`;

const Triangles = () => (
  <>
    <TrianglePink />
    <TriangleBlue />
  </>
);

export default Triangles;
