import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Concrete = styled(Img)`
  left: -160px;
  transform: scaleX(-1);
  ${tw`absolute! top-0 w-660 -z-1 sm:w-690 xl:hidden`}
  
  @media (min-width: 640px) {
    left: -160px;
  }
  
  @media (min-width: 1024px) {
    left: -410px;
    width: 768px;
  }
`;

const ConcreteBig = styled(Img)`
  left: -730px;
  width: 1090px;
  transform: scaleX(-1);
  ${tw`hidden absolute! top-0 -z-1 xl:block`}
`;

const Mark = styled(Img)`
  top: 390px;
  left: -80px;
  ${tw`hidden absolute! w-160 md:block`}
  
  @media (min-width: 1024px) {
    top: 260px;
    left: -120px;
  }

  @media (min-width: 1280px) {
    top: 340px;
    left: -210px;
  }
`;

const Beans = styled(Img)`
  top: -125px;
  right: -155px;
  ${tw`hidden absolute! w-172 lg:block`}
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      concrete: file(relativePath: { eq: "concrete.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      concreteBig: file(relativePath: { eq: "concrete-big.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beans: file(relativePath: { eq: "coffee-beans.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Concrete fluid={data.concrete.childImageSharp.fluid} />
      <ConcreteBig fluid={data.concreteBig.childImageSharp.fluid} />
      <Mark fluid={data.mark.childImageSharp.fluid} />
      <Beans fluid={data.beans.childImageSharp.fluid} />
    </>
  );
};

export default Images;
