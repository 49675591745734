import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`md:hidden`;

const ItemWrapper = tw.div`flex items-center text-left cursor-pointer`;

const ListStyle = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mr-18`}
`;

const Item = tw(Text)`w-120`;

const Triangle = styled.div`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #000;
  transform: scaleY(1);
  ${tw`w-0 h-0 ml-10 transition-transform duration-400`}

  ${Wrapper}.active & { transform: scaleY(-1); }
`;

const Nav = styled.div`
  background-image: linear-gradient(183deg, #D7EFFD, #D7EFFD 251px, #FFF 252px);
  ${tw`absolute left-0 h-0 w-320 -ml-10 text-center transition-all duration-400 overflow-hidden sm:w-440`}
  
  @media (min-width: 640px) {
    background-image: linear-gradient(183deg, #D7EFFD, #D7EFFD 257px, #FFF 258px);
  }
  
  ${Wrapper}.active & { ${tw`h-330`} }
`;

const NavItem = tw.div`py-10 first:pt-16 border-pink-300 border-b cursor-pointer last:border-0`;

const NavMobile = ({ array, taste, handleTaste }) => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [isMenuOpen, handleMenu] = useState(false);

  const setTaste = (val) => {
    handleTaste(val);
    handleMenu(false);
  };

  return (
    <Wrapper className={isMenuOpen ? 'active' : ''}>
      <ItemWrapper onClick={() => handleMenu(!isMenuOpen)}>
        <ListStyle fluid={data.listStyle.childImageSharp.fluid} />
        <Item
          fontSizes={16}
          fontWeight={600}
          lineHeight={1.4}
          letterSpacing={20}
        >
          <FormattedMessage id={`coffee.cold brew.types.${taste}`} />
        </Item>
        <Triangle />
      </ItemWrapper>
      <Nav>
        {array.map((number) => (
          <NavItem
            key={number}
            fontSizes={16}
            fontWeight={300}
            lineHeight={1.4}
            letterSpacing={20}
            onClick={() => setTaste(number)}
          >
            <FormattedMessage id={`coffee.cold brew.types.${number}`} />
          </NavItem>
        ))}
      </Nav>
    </Wrapper>
  );
};

NavMobile.propTypes = {
  array: PropTypes.arrayOf(PropTypes.number).isRequired,
  taste: PropTypes.number.isRequired,
  handleTaste: PropTypes.func.isRequired,
};

export default NavMobile;
