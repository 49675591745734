import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`md:hidden`;

const QuestionWrapper = tw.div`flex text-left cursor-pointer`;

const QuestionNumber = tw(Text)`pl-12 mr-8`;

const Question = styled(Text)`
  ${tw`mb-15`}
  ${({ active }) => active && tw`font-600`}
`;

const Answer = styled(Text)`
  ${tw`mt-15 mb-30`}

  b { ${tw`font-600`} }
`;

const Mobile = ({ number, activeQuestion }) => {
  const [isOpen, handleOpen] = useState(number === 1);

  useEffect(() => {
    handleOpen(number === activeQuestion);
  }, [number, activeQuestion]);

  return (
    <Wrapper onClick={() => handleOpen(!isOpen)}>
      <QuestionWrapper>
        <QuestionNumber fontSizes={9} fontWeight={600} letterSpacing={11}>
          {number}
        </QuestionNumber>
        <Question
          fontSizes={16}
          fontWeight={300}
          lineHeight={1.5}
          letterSpacing={20}
          active={isOpen}
        >
          <FormattedMessage id={`coffee.faq.questions.${number}.question`} />
        </Question>
      </QuestionWrapper>
      <AnimateHeight duration={800} height={isOpen ? 'auto' : 0}>
        <Answer
          fontSizes={16}
          fontWeight={300}
          lineHeight={1.5}
          letterSpacing={20}
        >
          <FormattedHTMLMessage id={`coffee.faq.questions.${number}.answer`} />
        </Answer>
      </AnimateHeight>
    </Wrapper>
  );
};

Mobile.propTypes = {
  number: PropTypes.number.isRequired,
  activeQuestion: PropTypes.number.isRequired,
};

export default Mobile;
