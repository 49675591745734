import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';

const links = [
  {
    link: 'https://sklep.etnocafe.pl/40-akcesoria',
    isExternal: true,
  },
  {
    link: '/coffee',
    isExternal: false,
  },
  {
    link: 'https://sklep.etnocafe.pl/241-kawa-wroasters',
    isExternal: true,
    hash: '',
  },
];

const Header = tw(Text)`mb-38 md:mb-40 md:text-center`;

const Items = tw.div`md:flex md:justify-between`;

const ItemWrapper = styled(Text)`
  ${tw`mb-38 md:w-230 lg:w-auto lg:max-w-xs`}
  
  :nth-of-type(2) { ${tw`lg:w-300`} }
`;

const Content = tw(Text)`mt-10 mb-26 lg:h-48`;

const Anchor = tw.div`cursor-pointer`;

const CTA = ({ handleQuestions }) => (
  <>
    <Header fontFamily="bebasNeue" fontSizes={28} fontWeight={700} lineHeight={2.04} letterSpacing={35}>
      <FormattedMessage id="coffee.wroasters.wroasters.title" />
    </Header>
    <Items>
      {links.map(({ link, isExternal }, index) => (
        <ItemWrapper key={link}>
          <Text fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
            <FormattedMessage id={`coffee.wroasters.wroasters.${index}.title`} />
          </Text>
          <Content fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
            <FormattedMessage id={`coffee.wroasters.wroasters.${index}.content`} />
          </Content>
          {isExternal ? (
            <Link to={link} isExternal={isExternal}>
              <Text
                fontSizes={16}
                fontWeight={600}
                lineHeight={1.5}
                letterSpacing={20}
                uppercase
                underline
              >
                <FormattedMessage id={`coffee.wroasters.wroasters.${index}.cta`} />
              </Text>
            </Link>
          ) : (
            <Anchor onClick={() => {
              handleQuestions(3);
              scrollTo('#FAQ');
            }}
            >
              <Text
                fontSizes={16}
                fontWeight={600}
                lineHeight={1.5}
                letterSpacing={20}
                uppercase
                underline
              >
                <FormattedMessage id={`coffee.wroasters.wroasters.${index}.cta`} />
              </Text>
            </Anchor>
          )}
        </ItemWrapper>
      ))}
    </Items>
  </>
);

CTA.propTypes = {
  handleQuestions: PropTypes.func.isRequired,
};

export default CTA;
