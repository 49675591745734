import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { coffeeLinks } from '@utils/variables';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { debounce, currentBreakpoint } from '@utils/helpers';

import Text from '@components/text';
import Box from '@components/coffee/etno-cafe/box';

const handleHeightComponent = (width, isEnglish) => {
  const steps = isEnglish ? {
    xs: 425, sm: 360, md: 409, lg: 410, xl: 410,
  } : {
    xs: 393, sm: 347, md: 390, lg: 400, xl: 400,
  };

  return steps[currentBreakpoint(width)];
};

const Wrapper = tw.div`md:relative md:w-1/2`;

const TextMobileCenter = tw(Text)`text-center md:text-left`;

const Title = tw(TextMobileCenter)`mb-35 px-10 lg:px-0`;

const ShowMore = styled(Text)`
  transition: opacity .5s, left 0s .5s;
  ${tw`absolute left-1/2 bottom-0 text-center cursor-pointer transform -translate-x-1/2 md:mb-0`}
  ${({ hide }) => hide && tw`-left-full opacity-0`}
  
  @media (min-width: 768px) { bottom: -20px; }
  
  @media (min-width: 1024px) { bottom: -35px; }
`;

const Boxes = ({ lang }) => {
  const windowInnerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const isEnglish = lang === 'en';
  const [isFullHeight, handleHeight] = useState(false);
  const [viewWidth, setWidth] = useState(windowInnerWidth);
  const [height, setHeight] = useState(425);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    // eslint-disable-next-line no-unused-vars
    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    setHeight(handleHeightComponent(viewWidth, isEnglish));
  }, [viewWidth, isEnglish]);

  return (
    <Wrapper>
      <Title fontSizes={16} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="coffee.etno cafe.boxes.header" />
      </Title>
      <AnimateHeight
        duration={800}
        height={isFullHeight ? 'auto' : height}
      >
        {coffeeLinks.map((link, index) => (
          <Box key={link} link={link} boxNumber={index} />
        ))}
      </AnimateHeight>
      <ShowMore
        uppercase
        underline
        fontSizes={16}
        fontWeight={600}
        letterSpacing={20}
        hide={isFullHeight}
        onClick={() => handleHeight(true)}
      >
        <FormattedMessage id="btn.show more" />
      </ShowMore>
    </Wrapper>
  );
};

Boxes.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Boxes;
