import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { navigationGoToShop } from '@utils/navigation';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';

const Link = tw(LinkDefault)`relative inline-block lg:mt-5`;

const Button = tw(ButtonDefault)`mt-30 py-10 px-40 lg:mt-10`;

const CTA = () => (
  <Link to={navigationGoToShop.path} isExternal={navigationGoToShop.externalLink}>
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id={navigationGoToShop.translation} />
      </Text>
    </Button>
  </Link>
);

export default CTA;
