import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import CTA from '@components/coffee/etno-cafe/coffee/cta';
import Grid from '@components/coffee/etno-cafe/coffee/grid';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`relative flex flex-col items-center w-320 px-10 md:w-auto lg:px-0`}
`;

const Title = tw(Text)`mb-40 text-center`;

const Coffee = () => (
  <Wrapper>
    <Title fontFamily="bebasNeue" fontSizes={42} fontWeight={300} lineHeight={[1.17, 1.48]} letterSpacing={52}>
      <FormattedHTMLMessage id="coffee.etno cafe.coffee" />
    </Title>
    <Grid />
    <CTA />
  </Wrapper>
);

export default Coffee;
