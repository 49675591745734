import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`mb-18 lg:mb-16`;

const Subtitle = styled(Text)`
  ${tw`mb-20 lg:mb-15`}
  
  b { ${tw`font-600`} }
`;

const Content = () => (
  <>
    <Title fontFamily="bebasNeue" fontSizes={54} fontWeight={300} lineHeight={1} letterSpacing={138}>
      <FormattedHTMLMessage id="coffee.header.title" />
    </Title>
    <Subtitle fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee.header.subtitle" />
    </Subtitle>
    <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="coffee.header.online" />
    </Text>
  </>
);

export default Content;
