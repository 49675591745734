import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import LinkDefault from '@components/link';
import ButtonDefault from '@components/button';

const links = [
  {
    link: 'https://sklep.etnocafe.pl',
    isExternal: true,
  },
  {
    link: '/coffee-houses',
    isExternal: false,
  },
  {
    link: '/for-business',
    isExternal: false,
  },
];

const Wrapper = styled.div`
  ${tw`
    relative flex flex-col justify-between w-full mb-70 last:mb-30
    md:w-314 md:mx-auto md:mb-100 md:last:mb-60
    lg:mb-60 lg:last:mb-60
    xl:w-320
  `}
`;

const Content = styled(Text)`
  ${tw`mt-16`}

  b { ${tw`font-600`} }
`;

const Link = tw(LinkDefault)`inline-block mt-30`;

const Button = tw(ButtonDefault)`w-220 mt-0 px-10 text-center`;

const Box = ({ children, number }) => (
  <Wrapper>
    { children }
    <div>
      <Text fontFamily="bebasNeue" fontSizes={42} lineHeight={1} letterSpacing={52}>
        <FormattedMessage id={`coffee.boxes.${number}.title`} />
      </Text>
      <Content fontSizes={14} fontWeight={300} lineHeight={1.4} letterSpacing={18}>
        <FormattedHTMLMessage id={`coffee.boxes.${number}.content`} />
      </Content>
    </div>
    <Link to={links[number].link} isExternal={links[number].isExternal}>
      <Button>
        <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id={`coffee.boxes.${number}.cta`} />
        </Text>
      </Button>
    </Link>
  </Wrapper>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  number: PropTypes.node.isRequired,
};

export default Box;
